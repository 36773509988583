<template>
  <div class="list">
    <div
      @click="() => toDetail(item)"
      class="item flex-col"
      v-for="(item, index) in list"
      :key="index"
    >
      <van-image class="img flex-1" :src="item.infoImage">
        <template v-slot:error>加载失败</template>
      </van-image>
      <p>{{ item.infoTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
    category: {
      type: String,
      default: '0',
    },
  },
  setup(props, context) {
    const toDetail = (item) => {
      context.emit('toDetail', item)
    }
    return {
      toDetail,
    }
  },
}
</script>

<style lang="less" scoped>
.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &::after {
    content: '';
    width: 31%;
  }
  .item {
    width: 31%;
    height: 50%;
    p {
      margin: 10px 0 20px;
      font-size: 14px;
      height: 40px;
      color: #3c414d;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
