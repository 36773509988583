<template>
  <Tab :list="tabList" :current="current" />
  <div class="flex-1 fff flex-col">
    <List class="flex-1" @toDetail="toDetail" :list="list"></List>
    <div v-if="allData.length <= 0" class="noData">暂无数据</div>
    <van-pagination 
      v-model="currentPage" 
      mode="simple"
      @change="handleCurrentChange"
      :total-items="allData.length"
      items-per-page='6'
    >
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
    </van-pagination>
  </div>
</template>

<script>
import { newsTabList } from '@/utils/tab'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import List from './components/list.vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { getList } from '@/api/news'

const arr = [
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpe1g',
    title: '文章标题描述文字',
    id: 1,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 2,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 2,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字',
    id: 1,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 2,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 2,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 2,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 3,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 4,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 5,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 3,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 4,
  },
  {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    title: '文章标题描述文字文章标题描述文字文章标题描述文字文章标题描述文字',
    id: 5,
  },
]

export default {
  components: {
    Tab,
    List,
  },
  setup() {
    const tabList = reactive(newsTabList)

    const route = useRoute()
    // console.log(route.query.category)
    let category = ref(route.query.category || '8')
    let current = ref(0)
    const setCurrent = (category) => {
      let num = 0
      switch (category) {
        case '9':
          num = 1
          break
        case '10':
          num = 2
          break

        default:
          num = 0
          break
      }
      return num
    }
    current.value = setCurrent(category.value)

    onBeforeRouteUpdate((to, form, next) => {
      category.value = to.query.category
      current.value = setCurrent(to.query.category)
      // 重新加载
      currentPage.value = 1
      init()
      next()
    })

    // let list = reactive(arr)
    let list = ref([])
    let allData = ref([])
    let currentPage = ref(1)
    let count = ref(12)
    // 获取数据
    const init = () => {
      getList(category.value).then((res) => {
        allData.value = res.result
        handleCurrentChange(1)
      })
    }
    init()
    function paginate(array, page_size, page_number) {
      return allData.value.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const handleCurrentChange = (val) => {
      console.log(`list: ${list.value}`);
      console.log(`当前页: ${val}`);
      list.value = paginate(list, 6, val);
    }

    // 跳转详情
    const router = useRouter()
    const toDetail = (item) => {
      router.push({
        path: '/news/detail',
        query: {
          id: item.infoId,
          category: category.value,
        },
      })
    }

    return {
      tabList,
      list,
      current,
      currentPage,
      count,
      category,
      toDetail,
      handleCurrentChange,
      allData,
      init
    }
  },
}
</script>

<style lang="less" scoped>
.flex-col {
  padding: 16px;
}
</style>
